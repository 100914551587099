<template>
  <div>
    <el-button
        v-loading.fullscreen.lock="fullscreenLoading"
        type="primary"></el-button>
    <div class="text-center fixed-top d-flex flex-column top">
      <div>
        <img :src="imgUrl" alt="logo" height="75px">
      </div>

    </div>
    <div class="col-12 col-md-8 m-auto pdf">
      <pdfs
          v-for="i in numPages"
          :key="i"
          :page="i"
          :src="src"
          :v-if="pdfShow"
      ></pdfs>
    </div>

  </div>

</template>

<script>
import pdfs from 'vue-pdf'

export default {
  name: 'pdf',
  components: {pdfs},
  data() {
    return {
      imgUrl: require('@assets/images/logo.svg'),
      src: '',
      pdfShow: false,
      numPages: undefined,
      fullscreenLoading: false
    }
  },
  methods: {},
  mounted() {
    var vm = this
    vm.fullscreenLoading = true
    this.$axios({
      url: '/Course/GetPDF?pdfid=' + vm.$route.query.id,
      method: 'GET',
      responseType: 'blob'
    }).then((res) => {
      const binaryData = []
      binaryData.push(res.data)
      let pdfUrl = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/pdf'}))
      vm.src = pdfs.createLoadingTask(pdfUrl)
      vm.pdfShow = true
      vm.src.promise.then(pdf => {
        vm.numPages = pdf.numPages
      })
      vm.fullscreenLoading = false
    })
  }
}
</script>

<style scoped>
.top {
  background-color: white;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid rgb(31 163 162);
}

.tips {
  color: #009695;
  font-size: 14px;
  font-weight: 400;
}

.pdf {
  padding-top: 95px;
  max-width: 1200px;
}


</style>